<template>
  <div>
    <v-snackbar class="snakbar-sty rounded-pill" transition="slide-x-reverse-transition" v-model="snackbar" :timeout="3000" :value="true" :color="snackbarclr" absolute outlined>
      <v-icon class="mr-2" :color="snackbarclr">mdi-alert-outline</v-icon>
      {{ snackmsgbar }}
      <v-icon @click="snackbar = false" class="float-right" :color="snackbarclr">mdi-close-circle</v-icon>
    </v-snackbar>
    <div v-if="!mainloader" class="body-row pb-3 px-4 px-md-6 px-lg-8 pt-lg-6">
      <img src="@/assets/zebu.svg" class="ml-n1" style="max-width: 90px" alt="zebulogo" />
      <v-card class="elevation-0">
        <p class="subtitle-2 font-weight-bold mb-5 txt-666">Welcome to Zebu</p>
        <p class="headline font-weight-black" :class="flowsso ? 'mb-3' : 'mb-8'">Login to {{ vcname }}</p>

        <div>
          <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate()">
            <div v-if="flowsso">
              <!-- <p class="font-weight-medium fs-17 mb-0">
                Select your Vendor
                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                  <path
                    d="M5.10423 0.5L4.85634 3.86901L7.95493 2.53944L8.56338 4.17324L5.13803 4.98451C5.30329 5.17981 5.48732 5.38638 5.69014 5.60423C5.89296 5.81455 6.09577 6.02864 6.29859 6.24648C6.50892 6.46432 6.71549 6.6784 6.91831 6.88873C7.12113 7.09906 7.30892 7.29437 7.48169 7.47465L6.12958 8.5L4.25916 5.6831L2.33239 8.5L0.991549 7.47465L3.35775 4.98451L0 4.12817L0.51831 2.53944L3.68451 3.88028L3.38028 0.5H5.10423Z"
                    fill="#EF5350"
                  />
                </svg>
              </p>
              <v-select append-icon="mdi-chevron-down" :items="ssoitems" v-model="ssois" class="mb-1"></v-select> -->
              <v-tabs fixed-tabs @change="sstTabtype()" height="40px" v-model="ssois">
                <v-tab class="text-none">MYNT</v-tab>
                <v-tab class="text-none">XTS</v-tab>
              </v-tabs>
              <v-divider class="mb-3"></v-divider>
            </div>
            <p class="font-weight-medium fs-17 mb-0">
              Enter your Client ID
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                <path
                  d="M5.10423 0.5L4.85634 3.86901L7.95493 2.53944L8.56338 4.17324L5.13803 4.98451C5.30329 5.17981 5.48732 5.38638 5.69014 5.60423C5.89296 5.81455 6.09577 6.02864 6.29859 6.24648C6.50892 6.46432 6.71549 6.6784 6.91831 6.88873C7.12113 7.09906 7.30892 7.29437 7.48169 7.47465L6.12958 8.5L4.25916 5.6831L2.33239 8.5L0.991549 7.47465L3.35775 4.98451L0 4.12817L0.51831 2.53944L3.68451 3.88028L3.38028 0.5H5.10423Z"
                  fill="#EF5350"
                />
              </svg>
            </p>
            <v-text-field v-model="userId" :disabled="loading" :rules="useridRules" class="mb-1" placeholder="Client ID" required oninput="this.value = this.value.toUpperCase()">
              <template #prepend-inner>
                <div class="mr-2">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.1333 12.6438C14.1335 12.4937 14.0975 12.3457 14.0282 12.2125C13.959 12.0792 13.8587 11.9647 13.7357 11.8785C12.052 10.7881 10.0709 10.248 8.06667 10.3329C6.06245 10.248 4.08138 10.7881 2.3976 11.8785C2.27466 11.9647 2.17432 12.0792 2.1051 12.2125C2.03588 12.3457 1.99983 12.4937 2 12.6438V14.9996H14.1333V12.6438Z"
                      stroke="#212121"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path d="M8.06647 7.53333C9.8706 7.53333 11.3331 6.0708 11.3331 4.26667C11.3331 2.46254 9.8706 1 8.06647 1C6.26234 1 4.7998 2.46254 4.7998 4.26667C4.7998 6.0708 6.26234 7.53333 8.06647 7.53333Z" stroke="#212121" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </template>
            </v-text-field>

            <p class="font-weight-medium fs-17 mb-0">
              Enter your Password
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                <path
                  d="M5.10423 0.5L4.85634 3.86901L7.95493 2.53944L8.56338 4.17324L5.13803 4.98451C5.30329 5.17981 5.48732 5.38638 5.69014 5.60423C5.89296 5.81455 6.09577 6.02864 6.29859 6.24648C6.50892 6.46432 6.71549 6.6784 6.91831 6.88873C7.12113 7.09906 7.30892 7.29437 7.48169 7.47465L6.12958 8.5L4.25916 5.6831L2.33239 8.5L0.991549 7.47465L3.35775 4.98451L0 4.12817L0.51831 2.53944L3.68451 3.88028L3.38028 0.5H5.10423Z"
                  fill="#EF5350"
                />
              </svg>
            </p>
            <v-text-field v-model="password" :disabled="loading" :append-icon="pwseye ? 'mdi-eye' : 'mdi-eye-off'" :rules="pwsRules" :type="pwseye ? 'text' : 'password'" class="mb-1" placeholder="Password" @click:append="pwseye = !pwseye">
              <template #prepend-inner>
                <div class="mr-2">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.25 10.875H12.75M4.5 7.5H4.5075M7.5 7.5H7.5075M10.5 7.5H10.5075M13.5 7.5H13.5075M3.9 13.5H14.1C14.9401 13.5 15.3601 13.5 15.681 13.3365C15.9632 13.1927 16.1927 12.9632 16.3365 12.681C16.5 12.3601 16.5 11.9401 16.5 11.1V6.9C16.5 6.05992 16.5 5.63988 16.3365 5.31901C16.1927 5.03677 15.9632 4.8073 15.681 4.66349C15.3601 4.5 14.9401 4.5 14.1 4.5H3.9C3.05992 4.5 2.63988 4.5 2.31901 4.66349C2.03677 4.8073 1.8073 5.03677 1.66349 5.31901C1.5 5.63988 1.5 6.05992 1.5 6.9V11.1C1.5 11.9401 1.5 12.3601 1.66349 12.681C1.8073 12.9632 2.03677 13.1927 2.31901 13.3365C2.63988 13.5 3.05992 13.5 3.9 13.5Z"
                      stroke="#212121"
                      stroke-width="1"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </template>
            </v-text-field>

            <p class="font-weight-medium fs-17 mb-0">
              Enter your {{ ssois == 1 ? "PIN" : "PAN/DOB/OTP" }}
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                <path
                  d="M5.10423 0.5L4.85634 3.86901L7.95493 2.53944L8.56338 4.17324L5.13803 4.98451C5.30329 5.17981 5.48732 5.38638 5.69014 5.60423C5.89296 5.81455 6.09577 6.02864 6.29859 6.24648C6.50892 6.46432 6.71549 6.6784 6.91831 6.88873C7.12113 7.09906 7.30892 7.29437 7.48169 7.47465L6.12958 8.5L4.25916 5.6831L2.33239 8.5L0.991549 7.47465L3.35775 4.98451L0 4.12817L0.51831 2.53944L3.68451 3.88028L3.38028 0.5H5.10423Z"
                  fill="#EF5350"
                />
              </svg>
            </p>
            <v-text-field
              v-model="twofact"
              :disabled="loading"
              :append-icon="twofacteye ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="ssois == 1 ? twofacteyeRulest : twofacteyeRules"
              :type="twofacteye ? 'text' : 'password'"
              class="mb-1"
              :placeholder="ssois == 1 ? 'PIN' : 'PAN/DOB/OTP'"
              @click:append="twofacteye = !twofacteye"
              required
              oninput="this.value = this.value.toUpperCase()"
            >
              <template #prepend-inner>
                <div class="mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path
                      d="M13.4444 6.33328C13.4444 5.87833 13.2709 5.42337 12.9237 5.07625C12.5766 4.72912 12.1216 4.55556 11.6667 4.55556M11.6667 11.6667C14.6122 11.6667 17 9.27885 17 6.33333C17 3.38781 14.6122 1 11.6667 1C8.72115 1 6.33333 3.38781 6.33333 6.33333C6.33333 6.5766 6.34962 6.81607 6.38116 7.0507C6.43305 7.4366 6.45899 7.62956 6.44153 7.75164C6.42334 7.8788 6.40018 7.94733 6.3375 8.05947C6.27733 8.16711 6.1713 8.27315 5.95923 8.48522L1.41656 13.0279C1.26282 13.1816 1.18596 13.2585 1.13099 13.3482C1.08225 13.4277 1.04633 13.5144 1.02456 13.6051C1 13.7074 1 13.8161 1 14.0335V15.5778C1 16.0756 1 16.3245 1.09688 16.5147C1.1821 16.6819 1.31809 16.8179 1.48534 16.9031C1.67549 17 1.9244 17 2.42222 17H4.55556V15.2222H6.33333V13.4444H8.11111L9.51478 12.0408C9.72685 11.8287 9.83289 11.7227 9.94053 11.6625C10.0527 11.5998 10.1212 11.5767 10.2484 11.5585C10.3704 11.541 10.5634 11.567 10.9493 11.6188C11.1839 11.6504 11.4234 11.6667 11.6667 11.6667Z"
                      stroke="#212121"
                      stroke-width="1"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </template>
            </v-text-field>
            <div class="mt-2" :style="`cursor: ${valid != true ? 'not-allowed' : 'auto'};`">
              <v-btn height="48px" :disabled="!valid" :loading="loading" type="submit" color="#000" class="text-none rounded-pill elevation-0 mb-1" block> <span class="white--text subtitle-1 font-weight-medium">Sign In</span></v-btn>
            </div>
            <p class="mb-0">
              <v-row no-gutters>
                <v-col cols="3" class="text-left pr-3">
                  <v-btn text :ripple="false" color="primary" class="forgotbtn px-0 mb-2 elevation-0" small @click="otpto()">
                    <span class="font-weight-bold text-none"> GET OTP </span>
                  </v-btn>
                </v-col>
                <v-col cols="9" class="text-right">
                  <v-list-item-title>
                    <v-btn text :ripple="false" color="primary" class="forgotbtn px-0 mb-2 elevation-0" small @click="forgotPws()">
                      <span class="font-weight-bold text-none"> Forgot password</span>
                    </v-btn></v-list-item-title
                  >
                </v-col>
              </v-row>
            </p>
          </v-form>
          <p class="subtitle-2 font-weight-medium black--text mb-0 text-center mt-2">Don't have an account yet? <a style="text-decoration: none; color: #0037b7" href="https://oa.mynt.in?ref=desk">Sign Up</a></p>
        </div>
      </v-card>
      <v-footer padless absolute color="#fff">
        <v-col class="text-center font-weight-medium fs-10 px-lg-8 px-md-6 px-lg-8 pb-2 txt-666" cols="12"
          ><span class="font-weight-bold fs-12 black--text"> Zebu Share and Wealth Managements Pvt. Ltd. </span><br />
          SEBI Registration No: INZ000174634 | NSE : 13179 | BSE : 6550 | MCX : 55730 | CDSL : 12080400 | AMFI ARN : 113118 | Research Analyst : INH200006044
        </v-col>
      </v-footer>
    </div>
    <v-overlay :value="mainloader" opacity="1" color="transparent" class="rounded-0">
      <div class="text-center">
        <v-card width="70%" color="transparent" class="elevation-0 mx-auto">
          <v-progress-circular color="primary" :size="60" :width="4" indeterminate></v-progress-circular>
        </v-card>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";
import {sha256} from "js-sha256";
import {mynturl, apikey, source, redurl, sessurl} from "../../apiUrl.js";

export default {
  data: () => ({
    snackbar: false,
    snackbarclr: "default",
    snackmsgbar: "",
    // signinform: true,
    valid: true,
    userId: "",
    useridRules: [(v) => !!v || "Client Id is required"],
    pwseye: false,
    password: "",
    pwsRules: [(v) => !!v || "Password is required"],

    twofacteye: false,
    twofact: "",
    twofacteyeRules: [
      (v) => !!v || "PAN/DOB/OTP is required",
      (v) => v.length >= 4 || "PAN/DOB/OTP of minimum 4 characters or must be valid",
      // v => /[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(v) || 'PAN number must be valid',
    ],
    twofacteyeRulest: [(v) => !!v || "PIN is required", (v) => v.length == 6 || "Enter your 6 digit PIN"],
    vcname: "MYNT",
    flowsso: false,

    // ssoitems: ["Mynt", 'XTS'],
    ssois: 0,

    loading: false,
    mainloader: true,
  }),

  created() {
    var url = new URL(window.location.href);
    let vc = url.searchParams.get("vc");
    let key = url.searchParams.get("key");

    if (vc && key) {
      window.location.assign(`${redurl}?vc=${vc}&key=${key}`);
      this.mainloader = true;
    } else {
      this.mainloader = false;
      if (vc == "instaoptions" || vc == "instaoptionsxts") {
        this.ssois = vc == "instaoptionsxts" ? 1 : 0;
        this.flowsso = true;
      } else {
        this.flowsso = false;
      }
      this.getBadges(vc);
    }
  },

  mounted() {
    //
  },

  methods: {
    validate() {
      this.$refs.form.validate();
      var shapass = sha256(this.password);
      var appkey = sha256(this.userId + apikey);
      if (this.$refs.form.validate()) {
        this.loading = true;
        var data = this.ssois == 1 ? {cc: this.userId, pass: btoa(this.password), pin: btoa(this.twofact)} : `jData={"uid":"${this.userId}","pwd":"${shapass}","factor2":"${this.twofact}","apkversion":"20220921","imei":"","vc":"PRO_MYNT","appkey":"${appkey}","source":"${source}"}`;
        var config = {
          method: "post",
          url: this.ssois == 1 ? `${sessurl}/xtsLogin` : `${mynturl}/QuickAuth`,
          headers: {
            "Content-Type": this.ssois == 1 ? "application/json" : "text/plain",
          },
          data: data,
        };
        var axiosThis = this;

        axios(config)
          .then(function (response) {
            axiosThis.loading = false;
            let vc = new URL(window.location.href).searchParams.get("vc");

            if (axiosThis.ssois == 1) {
              if (response.data && response.data.stat == "Ok") {
                window.location.assign(`${redurl}?actid=${axiosThis.userId}&cliname=${response.data.name}&accessToken=${response.data.data.token}&vc=${vc}`);

                // axiosThis.snackbarclr = "success";
                // axiosThis.snackmsgbar = "Login successfully";
              } else {
                axiosThis.snackbar = true;
                axiosThis.snackbarclr = "#f23645";
                axiosThis.snackmsgbar = response.data && response.data.msg ? response.data.msg : response.data;
              }
            } else {
              if (response.data.susertoken && response.data.actid) {
                if (vc) {
                  window.location.assign(`${redurl}?uid=${axiosThis.userId}&pwd=${encodeURIComponent(axiosThis.password)}&factor2=${axiosThis.twofact}&vc=${vc}`);
                } else {
                  axiosThis.snackbar = true;
                  axiosThis.snackbarclr = "#f23645";
                  axiosThis.snackmsgbar = "Invalid url or Wrong url";
                }
              } else if (response.data.emsg == "Invalid Input : Invalid User") {
                axiosThis.snackbar = true;
                axiosThis.snackbarclr = "#f23645";
                axiosThis.snackmsgbar = "Invalid User or Invalid Client ID";
                axiosThis.userId = "";
                axiosThis.password = "";
                axiosThis.twofact = "";
              } else if (response.data.emsg == "Invalid Input : Wrong Password") {
                axiosThis.snackbar = true;
                axiosThis.snackbarclr = "warning";
                axiosThis.snackmsgbar = "Wrong Password.";
                axiosThis.password = "";
              } else if (response.data.emsg == "Invalid Input : Invalid OTP") {
                axiosThis.snackbar = true;
                axiosThis.snackbarclr = "warning";
                axiosThis.snackmsgbar = "Invalid OTP.";
                axiosThis.twofact = "";
              } else if (response.data.emsg == "Invalid Input : Wrong PAN/DOB") {
                axiosThis.snackbar = true;
                axiosThis.snackbarclr = "warning";
                axiosThis.snackmsgbar = "Wrong PAN/DOB number.";
                axiosThis.twofact = "";
              } else if (response.data.emsg == "Invalid Input : Deactivated") {
                axiosThis.snackbar = true;
                axiosThis.snackbarclr = "warning";
                axiosThis.snackmsgbar = "Deactivated.";
                axiosThis.twofact = "";
              } else if (response.data.emsg == "Session Expired :  Invalid Session Key" || response.data.emsg == "Session Expired : Invalid Session Key") {
                axiosThis.snackbar = false;
                axiosThis.snackbarclr = "warning";
                axiosThis.snackmsgbar = "Invalid Session Key.";
              } else if (response.data.emsg == "Invalid Input : Invalid Access Type") {
                axiosThis.snackbar = false;
                axiosThis.snackbarclr = "warning";
                axiosThis.snackmsgbar = " Invalid Access Type, Mismatch in API URL.";
              } else if (response.data.emsg == "Invalid Input : User Blocked due to multiple wrong attempts") {
                axiosThis.snackbar = true;
                axiosThis.snackbarclr = "#f23645";
                axiosThis.snackmsgbar = "User Blocked due to multiple wrong attempts.";
                setTimeout(function () {
                  axiosThis.$router.push("/forgot_password");
                }, 2000);
              } else if (response.data.emsg == "Invalid Input : Change Password" || response.data.emsg == "Invalid Input : Password Expired") {
                axiosThis.snackbar = true;
                axiosThis.snackbarclr = "warning";
                if (response.data.emsg == "Invalid Input : Change Password") {
                  axiosThis.snackmsgbar = "Password as Change, please set new Password.";
                } else if (response.data.emsg == "Invalid Input : Password Expired") {
                  axiosThis.snackmsgbar = "Password Expired, please Change Password.";
                }
                axiosThis.userId = "";
                axiosThis.password = "";
                axiosThis.twofact = "";
                setTimeout(function () {
                  axiosThis.$router.push("/change_password");
                }, 2000);
              } else {
                axiosThis.snackbar = true;
                axiosThis.snackbarclr = "#f23645";
                if (response.data.emsg) {
                  axiosThis.snackmsgbar = response.data.emsg;
                } else {
                  axiosThis.snackmsgbar = "something went wrong, Kindly Sign in.";
                }
                axiosThis.userautosave = axiosThis.knowautosave;
                let routrs = localStorage.getItem("routset");
                localStorage.clear();
                localStorage.setItem("routset", routrs);
                localStorage.setItem("autoSave", axiosThis.userautosave);
                axiosThis.userId = "";
                axiosThis.password = "";
                axiosThis.twofact = "";
              }
            }
          })
          .catch(function (error) {
            console.log(error);
            axiosThis.loading = false;
            if (error.response.data.emsg) {
              axiosThis.snackbar = true;
              axiosThis.snackbarclr = "#f23645";
              axiosThis.snackmsgbar = error.response.data.emsg;
              axiosThis.userautosave = axiosThis.knowautosave;
              let routrs = localStorage.getItem("routset");
              localStorage.clear();
              localStorage.setItem("routset", routrs);
              localStorage.setItem("autoSave", axiosThis.userautosave);
            }
          });
      }
    },
    sstTabtype() {
      this.$router.push(`/?vc=${this.ssois == 0 ? "instaoptions" : "instaoptionsxts"}`);
      this.getBadges(this.ssois == 0 ? "instaoptions" : "instaoptionsxts");
    },
    getBadges(vc) {
      let config = {
        method: "get",
        url: `${sessurl}/get_vc_name?vc=${vc}`,
      };

      let axiosThis = this;
      axios
        .request(config)
        .then((res) => {
          if (res.data && res.data.name) {
            axiosThis.vcname = res.data.name;
          }
        })
        .catch((error) => {
          axiosThis.vcname = "MYNT";
          console.log(error);
        });
    },
    otpto() {
      this.$router.push("/OTP_sigin");
    },
    forgotPws() {
      this.$router.push("/forgot_password");
    },
  },
};
</script>

<style>
.tool-sty .v-toolbar__content {
  padding: 0 !important;
}
</style>
