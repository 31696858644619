// export const mynturl = "https://go.mynt.in/NorenWClientTV/";
export const mynturl = "https://go.mynt.in/NorenWClientTP";

// export const apikey = "|TVkey23022023zebu"; // for TV
export const apikey = "|Apikey18012023zebu"; // for TP Api

// export const source = "TV";
export const source = "API";

export const sessurl = "https://sess.mynt.in/"
export const redurl = "https://sess.mynt.in/OAuth"
export const oredurl = "https://sess.mynt.in/SSOAuth"

export const vc = "BONWOCKJXWKUTNFZXIUP"
