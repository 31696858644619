<template>
  <v-container class="body-card overflow-hidden pa-0" fill-height>
    <div class="body-part px-0 px-md-0">
      <v-card width="100%" class="ma-auto rounded-xl overflow-hidden body-temp">
      <div>
        <router-view />
      </div>
      </v-card>
    </div>
  </v-container>
</template>

<script>
// import SsoMyntin from '../views/mynt login/SsoMyntin.vue'

export default {
  // components: {
  //   SsoMyntin,
  // },
};
</script>

<style>
@import "../assets/style.css";
</style>
