import Vue from 'vue'
import VueRouter from 'vue-router'
import HelloWorld from '../components/HelloWorld.vue'

// import SsoInstain from '../views/mynt login/SsoInstain.vue'

import SsoMyntin from '../views/mynt login/SsoMyntin.vue'
import FgotpwsMyntin from '../views/mynt login/FgotpwsMyntin.vue'
import ChangepwdMyntin from '../views/mynt login/ChangepwdMyntin.vue'
import OtploginMynt from '../views/mynt login/OtploginMynt.vue'

import OrderMyntin from '../components/OrderMyntin.vue'

Vue.use(VueRouter)

const routes = [

    {
        path: '*',
        redirect: 'sso',
        name: 'layout',
        component: HelloWorld,
        children: [
            {
                path: '/sso',
                name: 'Sso Mynt login',
                component: SsoMyntin
            },
            // {
            //     path: '/SSOUrl',
            //     name: 'Sso instaoptions login',
            //     component: SsoInstain
            // },
            {
                path: '/forgot_password',
                name: 'Forgot Password',
                component: FgotpwsMyntin
            },
            {
                path: '/change_password',
                name: 'Change Password',
                component: ChangepwdMyntin
            },
            {
                path: '/OTP_sigin',
                name: 'Otp Sigin',
                component: OtploginMynt
            },
            {
                path: '/publisher',
                name: 'publisher js',
                component: OrderMyntin
            },
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router