<template>
  <div>
    <v-snackbar class="snakbar-sty rounded-pill" transition="slide-x-reverse-transition" v-model="snackbar" :timeout="3000" :value="true" :color="snackbarclr" absolute outlined>
      <v-icon class="mr-2" color="#2A2A2A">mdi-alert-outline</v-icon>
      {{ snackmsgbar }}
      <v-icon @click="snackbar = false" class="float-right" color="#2A2A2A">mdi-close-circle</v-icon>
    </v-snackbar>
    <div class="body-row pb-3 px-4 px-md-6 px-lg-8 pt-lg-6">
      <img src="@/assets/zebu.svg" class="ml-n1" style="max-width: 90px" alt="zebulogo" />
      <v-card class="elevation-0">
        <p class="subtitle-2 font-weight-bold mb-5 txt-666">Welcome to Zebu</p>
        <p class="headline font-weight-black mb-8">Forgot Password</p>

        <div>
          <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate()">
            <p class="font-weight-medium fs-17 mb-0">
              Enter your Client ID
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                <path
                  d="M5.10423 0.5L4.85634 3.86901L7.95493 2.53944L8.56338 4.17324L5.13803 4.98451C5.30329 5.17981 5.48732 5.38638 5.69014 5.60423C5.89296 5.81455 6.09577 6.02864 6.29859 6.24648C6.50892 6.46432 6.71549 6.6784 6.91831 6.88873C7.12113 7.09906 7.30892 7.29437 7.48169 7.47465L6.12958 8.5L4.25916 5.6831L2.33239 8.5L0.991549 7.47465L3.35775 4.98451L0 4.12817L0.51831 2.53944L3.68451 3.88028L3.38028 0.5H5.10423Z"
                  fill="#EF5350"
                />
              </svg>
            </p>
            <v-text-field :disabled="valdatadis" v-model="userId" :rules="useridRules" class="mb-1" placeholder="Client ID" required oninput="this.value = this.value.toUpperCase()">
              <template #prepend-inner>
                <div class="mr-2">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.1333 12.6438C14.1335 12.4937 14.0975 12.3457 14.0282 12.2125C13.959 12.0792 13.8587 11.9647 13.7357 11.8785C12.052 10.7881 10.0709 10.248 8.06667 10.3329C6.06245 10.248 4.08138 10.7881 2.3976 11.8785C2.27466 11.9647 2.17432 12.0792 2.1051 12.2125C2.03588 12.3457 1.99983 12.4937 2 12.6438V14.9996H14.1333V12.6438Z"
                      stroke="#212121"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path d="M8.06647 7.53333C9.8706 7.53333 11.3331 6.0708 11.3331 4.26667C11.3331 2.46254 9.8706 1 8.06647 1C6.26234 1 4.7998 2.46254 4.7998 4.26667C4.7998 6.0708 6.26234 7.53333 8.06647 7.53333Z" stroke="#212121" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </template>
            </v-text-field>

            <p class="font-weight-medium fs-17 mb-0">
              Enter your PAN
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                <path
                  d="M5.10423 0.5L4.85634 3.86901L7.95493 2.53944L8.56338 4.17324L5.13803 4.98451C5.30329 5.17981 5.48732 5.38638 5.69014 5.60423C5.89296 5.81455 6.09577 6.02864 6.29859 6.24648C6.50892 6.46432 6.71549 6.6784 6.91831 6.88873C7.12113 7.09906 7.30892 7.29437 7.48169 7.47465L6.12958 8.5L4.25916 5.6831L2.33239 8.5L0.991549 7.47465L3.35775 4.98451L0 4.12817L0.51831 2.53944L3.68451 3.88028L3.38028 0.5H5.10423Z"
                  fill="#EF5350"
                />
              </svg>
            </p>
            <v-text-field :disabled="valdatadis" v-model="pan" :append-icon="paneye ? 'mdi-eye' : 'mdi-eye-off'" :rules="panRules" :type="paneye ? 'text' : 'password'" class="mb-1" placeholder="PAN" @click:append="paneye = !paneye" required oninput="this.value = this.value.toUpperCase()">
              <template #prepend-inner>
                <div class="mr-2">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.25 10.875H12.75M4.5 7.5H4.5075M7.5 7.5H7.5075M10.5 7.5H10.5075M13.5 7.5H13.5075M3.9 13.5H14.1C14.9401 13.5 15.3601 13.5 15.681 13.3365C15.9632 13.1927 16.1927 12.9632 16.3365 12.681C16.5 12.3601 16.5 11.9401 16.5 11.1V6.9C16.5 6.05992 16.5 5.63988 16.3365 5.31901C16.1927 5.03677 15.9632 4.8073 15.681 4.66349C15.3601 4.5 14.9401 4.5 14.1 4.5H3.9C3.05992 4.5 2.63988 4.5 2.31901 4.66349C2.03677 4.8073 1.8073 5.03677 1.66349 5.31901C1.5 5.63988 1.5 6.05992 1.5 6.9V11.1C1.5 11.9401 1.5 12.3601 1.66349 12.681C1.8073 12.9632 2.03677 13.1927 2.31901 13.3365C2.63988 13.5 3.05992 13.5 3.9 13.5Z"
                      stroke="#212121"
                      stroke-width="1"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </template>
            </v-text-field>

            <p class="font-weight-medium fs-17 mb-0">
              Enter your Date of birth
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                <path
                  d="M5.10423 0.5L4.85634 3.86901L7.95493 2.53944L8.56338 4.17324L5.13803 4.98451C5.30329 5.17981 5.48732 5.38638 5.69014 5.60423C5.89296 5.81455 6.09577 6.02864 6.29859 6.24648C6.50892 6.46432 6.71549 6.6784 6.91831 6.88873C7.12113 7.09906 7.30892 7.29437 7.48169 7.47465L6.12958 8.5L4.25916 5.6831L2.33239 8.5L0.991549 7.47465L3.35775 4.98451L0 4.12817L0.51831 2.53944L3.68451 3.88028L3.38028 0.5H5.10423Z"
                  fill="#EF5350"
                />
              </svg>
            </p>
            <v-text-field :disabled="valdatadis" v-model="dob" class="mb-1" placeholder="DDMMYYYY" :rules="dobRules" type="number" required hide-spin-buttons></v-text-field>

            <div class="mt-3" :style="`cursor: ${valid != true ? 'not-allowed' : 'auto'};`">
              <v-btn height="48px" :disabled="!valid" type="submit" color="#000" class="text-none rounded-pill elevation-0 mb-1" block> <span class="white--text subtitle-1 font-weight-medium">Unblock & Reset</span></v-btn>
            </div>

            <p class="mb-0">
              <v-row no-gutters>
                <v-col cols="3" class="text-left pr-3">
                  <v-btn text :ripple="false" color="primary" class="forgotbtn px-0 mb-2 elevation-0" small @click="backtoMyntin()">
                    <span class="font-weight-bold text-none"> back to login </span>
                  </v-btn>
                </v-col>
                <v-col cols="9" class="text-right">
                  <v-list-item-title>
                    <v-btn text :ripple="false" color="primary" class="forgotbtn px-0 mb-2 elevation-0" small @click="changePws()">
                      <span class="font-weight-bold text-none"> Change password</span>
                    </v-btn></v-list-item-title
                  >
                </v-col>
              </v-row>
            </p>
          </v-form>
          <p class="subtitle-2 font-weight-medium black--text mb-0 text-center mt-2">Don't have an account yet? <a style="text-decoration: none; color: #0037b7" href="https://oa.mynt.in?ref=desk">Sign Up</a></p>
        </div>
      </v-card>
      <v-footer padless absolute color="#fff">
        <v-col class="text-center font-weight-medium fs-10 px-lg-8 px-md-6 px-lg-8 pb-2 txt-666" cols="12"
          ><span class="font-weight-bold fs-12 black--text"> Zebu Share and Wealth Managements Pvt. Ltd. </span><br />
          SEBI Registration No: INZ000174634 | NSE : 13179 | BSE : 6550 | MCX : 55730 | CDSL : 12080400 | AMFI ARN : 113118 | Research Analyst : INH200006044
        </v-col>
      </v-footer>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mynturl} from "../../apiUrl.js";

export default {
  data: () => ({
    snackbar: false,
    snackbarclr: "default",
    snackmsgbar: "",
    valdatadis: false,
    valid: true,
    userId: "",
    useridRules: [(v) => !!v || "Client Id is required"],
    dob: "",
    dobRules: [(v) => !!v || "Date of birth is required", (v) => /[0-3]{1}[0-9]{1}[0-1]{1}[0-9]{1}[0-9]{4}$/.test(v) || "Date of birth must be [DDMMYYYY eg: 01012000]", (v) => v.length == 8 || "Date of birth must be valid"],
    paneye: false,
    pan: "",
    panRules: [(v) => !!v || "PAN is required", (v) => /[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(v) || "PAN number must be valid"],
    theme: "Light",
  }),

  async created() {
    this.theme = "Dark";
  },

  mounted() {
    this.actid = localStorage.getItem("actid");
    if (this.actid != null) {
      this.userId = this.actid;
    } else {
      this.userId = "";
    }
  },

  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate() != false) {
        var axiosThis = this;
        var data = `jData={"uid":"${this.userId}","pan":"${this.pan}","dob":"${this.dob}"}`;
        var config = {
          method: "post",
          url: `${mynturl}/ForgotPassword`,
          headers: {
            "Content-Type": "text/plain",
            Cookie: "NWC_ID=3e03100f85c0155ffb62d5d9bcc0ee273e804c489e430ddb9e00eda1aab50f54",
          },
          data: data,
        };
        // console.log("pandob data", data);
        axios(config)
          .then(function (response) {
            // // console.log(JSON.stringify(response.data));
            // console.log("pandob", response.data);
            if (response.data.emsg == "Error Occurred : Wrong user id or user details") {
              axiosThis.snackbar = true;
              axiosThis.snackbarclr = "#f23645";
              axiosThis.snackmsgbar = "Invalid User or Invalid Client ID";
              axiosThis.userId = "";
              axiosThis.dob = "";
              axiosThis.pan = "";
            } else if (response.data.stat == "Ok") {
              axiosThis.valdatadis = true;
              localStorage.setItem("FgotPwduserid", axiosThis.userId);
              axiosThis.snackbar = true;
              axiosThis.snackbarclr = "#22ab94";
              axiosThis.snackmsgbar = "New password is send through email/SMS.";
              localStorage.removeItem("ylisft");
              localStorage.removeItem("actid");
              localStorage.removeItem("email");
              // localStorage.removeItem("loginway");
              localStorage.removeItem("susertoken");
              setTimeout(function () {
                axiosThis.$router.push("/change_password");
              }, 2000);
            } else {
              axiosThis.snackbar = true;
              axiosThis.snackbarclr = "#f23645";
              if (response.data.emsg) {
                axiosThis.snackmsgbar = response.data.emsg;
              } else {
                axiosThis.snackmsgbar = "something went wrong, Kindly try again.";
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    backtoMyntin() {
      this.$router.push("/sso");
    },
    changePws() {
      this.$router.push("/Change_Password");
    },
  },
};
</script>
