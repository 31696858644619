<template>
  <div>
    <v-snackbar class="snakbar-sty rounded-pill" transition="slide-x-reverse-transition" v-model="snackbar" :timeout="3000" :value="true" :color="snackbarclr" absolute outlined>
      <v-icon class="mr-2" color="#2A2A2A">mdi-alert-outline</v-icon>
      {{ snackmsgbar }}
      <v-icon @click="snackbar = false" class="float-right" color="#2A2A2A">mdi-close-circle</v-icon>
    </v-snackbar>
    <div class="body-row pb-3 px-4 px-md-6 px-lg-8 pt-lg-6">
      <img src="@/assets/zebu.svg" class="ml-n1" style="max-width: 90px" alt="zebulogo" />
      <v-card class="elevation-0">
        <p class="subtitle-2 font-weight-bold mb-5 txt-666">Welcome to Zebu</p>
        <p class="headline font-weight-black mb-8">Get OTP</p>

        <div>
          <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate()">
            <p class="font-weight-medium fs-17 mb-0">
              Enter your Client ID
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                <path
                  d="M5.10423 0.5L4.85634 3.86901L7.95493 2.53944L8.56338 4.17324L5.13803 4.98451C5.30329 5.17981 5.48732 5.38638 5.69014 5.60423C5.89296 5.81455 6.09577 6.02864 6.29859 6.24648C6.50892 6.46432 6.71549 6.6784 6.91831 6.88873C7.12113 7.09906 7.30892 7.29437 7.48169 7.47465L6.12958 8.5L4.25916 5.6831L2.33239 8.5L0.991549 7.47465L3.35775 4.98451L0 4.12817L0.51831 2.53944L3.68451 3.88028L3.38028 0.5H5.10423Z"
                  fill="#EF5350"
                />
              </svg>
            </p>
            <v-text-field :disabled="valdatadis" v-model="otpuserId" :rules="otpuseridRules" class="mb-1" placeholder="Client ID" required oninput="this.value = this.value.toUpperCase()"></v-text-field>

            <v-text-field
              :disabled="valdatadis"
              v-model="otppan"
              :append-icon="otppaneye ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="otppanRules"
              :type="otppaneye ? 'text' : 'password'"
              class="mb-1"
              placeholder="PAN"
              @click:append="otppaneye = !otppaneye"
              required
              oninput="this.value = this.value.toUpperCase()"
            ></v-text-field>

            <div class="mt-3" :style="`cursor: ${valid != true ? 'not-allowed' : 'auto'};`">
              <v-btn height="48px" :disabled="!valid" type="submit" color="#000" class="text-none rounded-pill elevation-0 mb-1" block> <span class="white--text subtitle-1 font-weight-medium">Send OTP</span></v-btn>
            </div>

            <p class="mb-0">
              <v-row no-gutters>
                <v-col cols="3" class="text-left pr-3">
                  <v-btn text :ripple="false" color="primary" class="forgotbtn px-0 mb-2 elevation-0" small @click="goback()">
                    <span class="font-weight-bold text-none"> back to login </span>
                  </v-btn>
                </v-col>
                <v-col cols="9" class="text-right">
                  <v-list-item-title>
                    <v-btn text :ripple="false" color="primary" class="forgotbtn px-0 mb-2 elevation-0" small @click="forgotPws()">
                      <span class="font-weight-bold text-none"> Forgot password</span>
                    </v-btn></v-list-item-title
                  >
                </v-col>
              </v-row>
            </p>
          </v-form>
          <p class="subtitle-2 font-weight-medium black--text mb-0 text-center mt-2">Don't have an account yet? <a style="text-decoration: none; color: #0037b7" href="https://oa.mynt.in?ref=desk">Sign Up</a></p>
        </div>
      </v-card>
      <v-footer padless absolute color="#fff">
        <v-col class="text-center font-weight-medium fs-10 px-lg-8 px-md-6 px-lg-8 pb-2 txt-666" cols="12"
          ><span class="font-weight-bold fs-12 black--text"> Zebu Share and Wealth Managements Pvt. Ltd. </span><br />
          SEBI Registration No: INZ000174634 | NSE : 13179 | BSE : 6550 | MCX : 55730 | CDSL : 12080400 | AMFI ARN : 113118 | Research Analyst : INH200006044
        </v-col>
      </v-footer>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mynturl} from "../../apiUrl.js";

export default {
  data: () => ({
    snackbar: false,
    snackbarclr: "default",
    snackmsgbar: "",
    valdatadis: false,
    valid: true,
    otpuserId: "",
    otpuseridRules: [(v) => !!v || "Client Id is required"],
    otppaneye: false,
    otppan: "",
    otppanRules: [(v) => !!v || "PAN is required", (v) => /[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(v) || "PAN number must be valid"],
  }),

  async created() {},

  mounted() {
    this.actid = localStorage.getItem("actid");
    if (this.actid != null) {
      this.otpuserId = this.actid;
    } else {
      this.otpuserId = "";
    }
  },

  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate() != false) {
        var axiosThis = this;
        var data = `jData={"uid":"${this.otpuserId}","pan":"${this.otppan}"}`;
        var config = {
          method: "post",
          url: `${mynturl}/FgtPwdOTP`,
          headers: {
            "Content-Type": "text/plain",
            Cookie: "NWC_ID=3e03100f85c0155ffb62d5d9bcc0ee273e804c489e430ddb9e00eda1aab50f54",
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            // console.log(JSON.stringify(response.data));
            // console.log("validate", response.data);
            if (response.data.ReqStatus == "OTP generation success") {
              axiosThis.valdatadis = true;
              axiosThis.snackbar = true;
              axiosThis.snackbarclr = "#22ab94";
              axiosThis.snackmsgbar = "OTP generation success, send through email/SMS.";
              axiosThis.otpuserId = response.data.uid;
              setTimeout(function () {
                axiosThis.$router.push("/sso");
              }, 2000);
            } else if (response.data.emsg == "Error Occurred : Wrong user id or user details" || response.data.ReqStatus == "Invalid User") {
              axiosThis.snackbar = true;
              axiosThis.snackbarclr = "#f23645";
              axiosThis.snackmsgbar = "Invalid User or Invalid Client ID.";
            } else if (response.data.ReqStatus == "Invalid PAN") {
              axiosThis.snackbar = true;
              axiosThis.snackbarclr = "#f23645";
              axiosThis.snackmsgbar = "Wrong PAN number.";
            } else {
              axiosThis.snackbar = true;
              axiosThis.snackbarclr = "#f23645";
              if (response.data.emsg || response.data.ReqStatus) {
                axiosThis.snackmsgbar = response.data.emsg;
              } else {
                axiosThis.snackmsgbar = "something went wrong, Kindly try again.";
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    goback() {
      this.$router.push("/sso");
    },
    forgotPws() {
      this.$router.push("/forgot_password");
    },
  },
};
</script>
