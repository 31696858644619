<template>
  <v-app>
    <!-- <v-main class="mainwrap"> -->
    <div>
      <router-view />
    </div>
    <!-- </v-main> -->
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),

  created() {
    // window.addEventListener('resize', document.body.style.zoom = "90%");
  }
};
</script>
